div .loading-screen-content {
    display: flex;
    flex-direction: row;
    height: 31.1em;
}

div .loading-screen-content .loader {
    margin: auto;
}

div .loading-screen-content .loader h3 {
    margin-top: 80px;
}
