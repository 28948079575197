.content {
  display: flex;
  flex-direction: column;
  font-family: Lato, monospace;
}

.minhas-notas {
  display: flex;
  flex-direction: column;
}

.notas-title {
  /* Font Style */
  font-size: 30px;
  font-family: 'Baloo Bhai', cursive;
  color: #00c200;
  /* Alignment */
  margin: 40px auto 40px;
}

.head-icon {
  position: absolute;
  margin-left: -2em;
  margin-top: -0.1em;
}

.head-icon:hover {
  cursor: pointer;
}

.filter-button {
  width: 119.5px;
  height: 48px;
  text-align: center;
  border-radius: 50px;
  border: solid 2px #e1173f;
  background-color: #ffffff;
}

.filter-button:hover {
  cursor: pointer;
}

.filter-button-text {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
}

.no-decoration {
  padding: .9em;
  background: none;
  border: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.29;
  color: #e1173f;
}

.form-custom-select {
  width: 80px;
  height: 48px;
  text-align: center;
  border-radius: 50px;
  text-align-last: center;
  border: solid 2px #e1173f;
  background-color: #ffffff;
}

.form-custom-select:active {
  /*background: url('../../img/arrow.png') 90% / 10% no-repeat !important;*/
}

.status-icon {
  height: 24px;
  width: 24px;
}

.custom-table {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  text-align: center;
  font-size: 18px;
}

.spacing-table {
  height: 20px;
}

.icon-pending {
  color: #ff8d8d;
}

.action-icon-pending {
  /*background-color: #ff8d8d;*/
}

.icon-confirmed {
  color: #50bc49;
}

.action-icon-confirmed {
  background-color: #50bc49;
}

.icon-error{
  color: #df3a3a;
}

.action-icon-error {
  background-color: #df3a3a;
}

.table-precessing {
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 3px #747474;
}

.icon-processing {
  color: #747474;
}

.action-icon-processing {
  background-color: #747474;
}

.action-icon {
  height: 35px;
  color: #3b69ff;
  border-radius: 50px;
  width: unset !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.action-icon:hover {
  cursor: pointer;
  background-color: white;
}

.icon-width {
  width: 1em !important;
  margin-left: 1.5em !important;
}

.action-width {
  width: 3em !important;
}

.fa-plus:before {
  vertical-align: -webkit-baseline-middle;
}

.fa-paperclip:before {
  vertical-align: -webkit-baseline-middle;
}

.pagination {
  align-self: center;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
}

.pages {
  margin-bottom: 5em;
  text-align: center;
}

.pagination-item {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #3b69ff;
  width: 36px;
  height: 36px;
  margin: 1px;
  padding-top: 6px;
}

.page-list {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: auto !important;
}

.active-page {
  background-color: #3b69ff;
  color: white;
  border-radius: 80px;
}

.div-table {
  display: table;
  width: 75em;
  border-spacing: 20px; /* cellspacing:poor IE support for  this */
}

.div-table-row-header {
  margin: 1em;
  width: auto;
  clear: both;
}

.div-table-row {
  margin-bottom: 1em;
  display: grid;
  width: 70em;
  height: 77px;
  clear: both;
  border-radius: 8px;
  box-shadow: 3px 3px 6px 3px rgba(0, 0, 0, 0.16);
  border: solid 1px #e3e3e3;
  background-color: #ffffff;
}

.div-table-col {
  margin: auto;
  float: left; /* fix for  buggy browsers */
  display: table-column;
  align-self: center;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 1.22;
  text-align: center;
  color: #000000;
}

.div-table-col-header {
  float: left; /* fix for  buggy browsers */
  display: table-column;
  width: 2em;
  align-self: center;
  margin: 1em 1em 1em 7.5em;
}

.item-icon-sizer {
  width: 3.6em;
}

.first-item-header {
  margin-left: 5em;
}

.cnpj-sizer {
  width: 12em;
}

.align-table {
  margin-top: 2em;
  margin-bottom: 2em;
  text-align: -webkit-center;
}

.item-header {
  width: 7.5em;
  margin-left: 2em;
}

/*  expanded row  */
.opened-row {
  height: 210px;
  justify-self: center;
}

.opened-col {
  align-self: start;
}

.row-table {
  display: flex;
  width: 69.68em;
}

.line {
  height: 3px;
}

.client-info {
  margin: 3em 0;
  width: 268px;
  height: 22px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.buttons {
  margin-top: 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.cancel-button {
  margin-right: 1em;
  width: 119.5px;
  height: 48px;
  border-radius: 50px;
  border: solid 2px #e1173f;
  background-color: #ffffff;
}

.cancel-button:hover {
  color: #ba1437;
  cursor: pointer;
  border: solid 2px #ba1437;
}

.open-button {
  width: 212.3px;
  height: 48px;
  border-radius: 50px;
  background-color: #e1173f;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  color: #ffffff;
  padding: 1em;
  text-align: center;
}

.open-button:hover {
  color: #fefefe;
  cursor: pointer;
  text-decoration: unset;
  background-color: #ba1437;
}

.clip-icon {
  filter: invert(1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  margin-top: -0.2em;
}

/*  Filter Modal */

.input-sizer {
  display: flex;
  flex-direction: row;
  width: 30em !important;
}

.input-sizer-double {
  width: 15em !important;
}

.confirm-filter {
  width: 10em;
  height: 3em;
  border-radius: 4px;
  background-color: #50bc49;
}

.confirm-filter-text {
  font-family: Lato;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.modal-content-center {
  text-align: -webkit-center;
}

.modal-custom-title {
  object-fit: contain;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #0f1b45;
}

.search-bar {
  text-align: -webkit-center;
}

.search-bar-group {
  align-content: center;
  border: solid 1px black !important;
  border-radius: 0px !important;
  height: 50px !important;
}

.search-icon {
  z-index: 9999;
  align-self: center;
  color: black;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.custom-modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 40em;
  height: 22em;
  border-radius: 8px;
}

/* Modal 2 Content/Box */
.custom-modal-content-2 {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 20em;
  height: 12em;
  border-radius: 8px;
}

.div-content {
  display: flex;
  flex-direction: column;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.continue-button {
  margin-top: 3em;
  border-radius: 4px;
  background-color: #50bc49;
  text-align: center;
  width: 223px;
  height: 58px;
  padding: 1em;
}

.continue-button:hover {
  cursor: pointer;
}

.confirm-filter {
  width: 10em;
  border-radius: 4px;
  background-color: #50bc49;
}

.confirm-filter-text {
  font-family: Lato;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.align-text {
  margin: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-text {
  /* Font Style */
  font-size: 22px;
  font-family: 'Baloo Bhai', cursive;
  color: #0C1132;
}

.esconder {
  display: none !important;
}

.number-size {
  width: 4em;
  margin-left: 1.8em;
}

.cnpj-size {
  width: 8em;
  margin-left: 2.4em;
}

.value-size {
  width: 5em;
  margin-left: 2.6em;
}

.date-size {
  width: 8em;
}

.finalDate-size {
  width: 8em;
}

.status-size {
  width: 8em;
}

.loading-content {
  margin-top: 100px;
}

@media only screen and (max-width: 767px) {
  .row-table {
    width: unset;
  }

  .div-table {
    width: auto;
  }

  .div-table-row {
    width: 20em;
    height: 142px;
  }

  .div-table-col {
    margin: auto;
  }

  .number-size {
    width: unset;
    margin: auto auto auto 1em;
  }

  .value-size {
    width: unset;
    margin-left: 1em
  }

  .date-size {
    width: unset;
    margin-left: 1em
  }

  .mobile-aligner {
    width: 15em;
    display: flex;
    flex-direction: column;
  }

  .opened-row {
    height: 320px;
  }

  .expanded-row {
    margin-top: 30px;
  }

  .line {
    height: 3px;
    width: 20em;
    margin-left: unset;
  }
}
