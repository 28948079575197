* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: Montserrat, sans-serif;
}

a, button {
  font-family: Montserrat, sans-serif !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.paragraph {
  margin: 0;
  color: #0F1B45;
  font-size: 20px;
  line-height: 1.2;
}

.paragraph_small {
  font-size: 14px;
  line-height: 1.2;
  overflow-y: hidden;
}

.paragraph_big {
  font-size: 30px;
}

.paragraph_white {
  color: #fff;
}

.bold {
  font-weight: bold;
}

 /*COMMON STYLES*/

.content {
  display: flex;
  flex-direction: column;
}

.custom-title {
  font-size: 24px;
  font-family: 'Baloo Bhai', cursive;
  color: #45b347;
}

.header {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.header-sub {
  font-weight: bold;
  font-size: medium;
  color: #0C1132;
}

.checks {
  display: flex;
  flex-direction: row;
  align-self: center;
}

.custom-container {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-weight: 300;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: black;
}

.custom-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: solid 1px #666666;
}

.custom-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.custom-container input:checked ~ .checkmark {
  background-color: #747474;
}

.customcheck {
  color: white;
}

/* FORM EXIBIDO QUANDO SIM É SELECIONADO */

.history-footer-buttons {
  display: flex;
  margin-top: 1.4rem;
  align-self: center;
  flex-direction: row;
  margin-bottom: 60px;
}

.history-back-button {
  align-self: center;
}

.history-back-text {
  width: 46px;
  height: 26px;
  font-family: Lato;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  padding: unset;
  border: none;
  background: none;
}

.history-back-icon {
  width: 11.9px;
  height: 20.9px;
  font-size: 20px;
  margin-right: 8px;
}

.history-continue-button {
  margin-left: 80px;
  border-radius: 4px;
  background-color: #50bc49;
  text-align: center;
  width: 223px;
  height: 58px;
  padding: 1em;
}

.history-custom-buttom {
  width: 121px;
  height: 22px;
  object-fit: contain;
  font-family: Lato;
  font-size: 1.1em;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  padding: unset;
  border: none;
  background: none;
}

 /*FORM STYLES*/

form {
  display: block;
}

form input {
  width: 320px;
  height: 45px;
  color: #333;
  font-size: 18px;
  border: solid 1px #666666;
  background-color: #ffffff;
  padding: 0 10px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.label-content {
  display: flex;
}

.input-label {
  cursor: text;
  color: #666666;
  font-size: 1.3em;
  margin-top: 0.8em;
  margin-left: 0.8em;
  position: absolute;
  transition: font-size 0.4s, margin-top 0.4s, margin-left 0.4s;
}

input:focus + .input-label {
  font-size: 1em !important;
  margin-left: 1em !important;
  margin-top: -1em !important;
}

input:valid + .input-label {
  font-size: 1em;
  margin-left: 1em;
  margin-top: -1em;
}

input:invalid + .input-label {
  font-size: 1.3em;
  margin-top: 0.8em;
  margin-left: 0.8em;
}

.written_input {
  font-size: 1em !important;
  margin-left: 1em !important;
  margin-top: -1em !important;
}

.empty_input {
  font-size: 1.1em !important;
  margin-top: 1.3em !important;
  margin-left: .6em !important;
}

.label {
  position: relative;
  display: block;
}

.label-spacer {
  margin-right: 1.3em;
}

.label_copied::after {
  content: 'Item copiado!';

  position: absolute;
  top: 15px;
  left: 10px;

  height: auto;
  width: 90%;

  color: #45b347;
  font-size: 1rem;

  background-color: #fff;
}

.input {
  min-height: 48px;
  width: 100%;
  padding: .8rem 1.25rem .6rem 1.25rem;

  color: #333333;
  font-size: 1rem;
  border: 1px solid #707070;
}

.input_copy {
  cursor: pointer;
}

.input[readonly] {
  font-size: 1rem;
  border: 0;
}

.input::placeholder {
  font-weight: lighter;
  font-style: italic;
}

.input_status {
  width: 285px;
  background-color: white;
}

.input_perPage {
  background-color: white;
}

.label_perPage {
  width: 7em;
  margin-left: auto;
}

.label_copied .input {
  border: 2px solid #45b347;
}

.label__p {
  margin: 0;

  color: #002042;
  font-size: .875rem;
  font-weight: bold;
}

.label__p_float {
  position: absolute;
  top: -6px;
  left: .8rem;
  z-index: 2;

  background-color: #fff;
  padding: 0 .4rem;
}

.error-message {
  color: #ff8d8d;
}

.form-input-group {
  padding-top: 14px;
  margin-bottom: 20px;
}

.custom-input {
  margin-bottom: 20px !important;
}

.react-confirm-alert-body h1 {
  color: #e1173f;
}

.desktop-table {
  display: block;
}

.mobile-table {
  display: none;
}

.input-container {
  margin: auto auto 20px;
}

@media only screen and (max-width: 767px) {
  .desktop-table {
    display: none;
  }

  .mobile-table {
    display: block;
  }

  input, select, textarea {
    margin-right: auto;
    margin-left: auto;
    width: 20em !important;
  }

  .row {
    width: auto !important;
  }

  .mt-5 {
    margin-top: unset;
  }

  .label-content {
    width: auto !important;
    margin: auto;
  }

  .form-input-group {
    flex-direction: column !important;
    padding: unset !important;
    width: auto !important;
    margin-right: auto;
    margin-left: auto;
  }

  .double-input-align {
    flex-direction: column !important;
    padding: unset !important;
    width: auto !important;
    margin-right: auto;
    margin-left: auto;
  }

  .raz-social-content {
    width: auto !important;
  }

  .pb-container {
    width: 60% !important;
  }

  .social-input-about {
    width: 20em !important;
  }

  .footer-buttons-about {
    width: auto !important;
  }

  .double-input-align-value {
    flex-direction: column !important;
  }

  .custom-input-value {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .footer-buttons-value .pb-container {
    margin-left: 20px;
  }

  .custom-input-2 {
    margin-left: auto !important;
  }

  .input-tel-size {
    margin-left: 0;
  }

  .input-container div:nth-child(2) {
    margin-top: 20px;
  }
}
