.head-title-success {
    /* Font Style */
    font-size: 26px;
    font-family: 'Baloo Bhai', cursive;
    color: #00c200;
    /* Alignment */
    margin: 40px auto 0;
}

.header-sub-success {
    width: 406px;
    height: 48px;
    object-fit: contain;
    font-family: Lato;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    align-self: center;
    color: #0f1b45;
}

.footer-buttons-success {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-top: 1.4rem;
}

.continue-button-success {
    border-radius: 4px;
    background-color: #50bc49;
    text-align: center;
    width: 258px;
    height: 62px;
    padding: 1em;
    cursor: pointer;
}

.continue-button-bill {
    border-radius: 4px;
    background-color: #50bc49;
    text-align: center;
    width: 258px;
    height: 62px;
    padding: 1em;
    cursor: pointer;
    margin-bottom: 22px;
}

.custom-button-success {
    width: 204px;
    height: 32px;
    font-family: Lato;
    font-size: 26px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.success-check {
    width: 149px;
    height: 148px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-radius: 80px;
    align-self: center;
    text-align: center;
}

.custom-check-success {
    margin-top: 0.6em;
    font-size: 70px;
    color: #23ab6d;
}

.pending-content-container {
    margin: auto;
}
