@import url('https://fonts.googleapis.com/css?family=Baloo+Bhai|Lato|Montserrat&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.attachment-back-button {
  display: flex;
  color: #3b69ff;
  align-self: center;
  margin-right: 30px;
}

/*BUTTON STYLE*/
.pb-container {
  display: inline-block;
  text-align: center;
  width: 100%;
}
.pb-container .pb-button {
  text-align: center;
  color: #ffffff;
  width: 212px;
  height: 48px;
  border-radius: 50px;
  border: none;
  background-color: #e1173f;
  cursor: pointer;
  padding: 0.7em 1em;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  transition: background-color 0.3s, width 0.3s, border-width 0.3s, border-color 0.3s, border-radius 0.3s;
}
.pb-container .pb-button span {
  display: inherit;
  transition: opacity 0.3s 0.1s;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  text-align: center;
}
.pb-container .pb-button svg {
  height: 54px;
  width: 54px;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.pb-container .pb-button svg path {
  opacity: 0;
  fill: none;
}
.pb-container .pb-button svg.pb-progress-circle {
  animation: spin 0.9s infinite cubic-bezier(0.085, 0.260, 0.935, 0.710);
}
.pb-container .pb-button svg.pb-progress-circle path {
  stroke: #e1173f;
  stroke-width: 5;
}
.pb-container .pb-button svg.pb-checkmark path,
.pb-container .pb-button svg.pb-cross path {
  stroke: #fff;
  stroke-linecap: round;
  stroke-width: 4;
}
.pb-container.disabled .pb-button {
  cursor: not-allowed;
}
.pb-container.loading .pb-button {
  width: 54px;
  border-width: 6.5px;
  border-color: #ddd;
  cursor: wait;
  background-color: transparent;
  padding: 0;
}
.pb-container.loading .pb-button span {
  transition: all 0.15s;
  opacity: 0;
  display: none;
}
.pb-container.loading .pb-button .pb-progress-circle > path {
  transition: opacity 0.15s 0.3s;
  opacity: 1;
}
.pb-container.success .pb-button {
  border-color: #A0D468;
  background-color: #A0D468;
}
.pb-container.success .pb-button span {
  transition: all 0.15s;
  opacity: 0;
  display: none;
}
.pb-container.success .pb-button .pb-checkmark > path {
  opacity: 1;
}
.pb-container.error .pb-button {
  border-color: #ED5565;
  background-color: #ED5565;
}
.pb-container.error .pb-button span {
  transition: all 0.15s;
  opacity: 0;
  display: none;
}
.pb-container.error .pb-button .pb-cross > path {
  opacity: 1;
}
@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
    transform-origin: center center;
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
    transform-origin: center center;
  }
}
